@import './../variables';

.heroSection {
    padding: 0;
    height: calc(100vh - $header-height);
    background: url('../../../public//images/home/Akiira\ Estates\ Gasogi.webp');
    background-blend-mode: overlay;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .content {
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(180deg, rgba(9, 50, 68, 1) 0%, rgba(9, 50, 68, 0.702140231092437) 99%);
        background: -webkit-linear-gradient(180deg, rgba(9, 50, 68, 1) 0%, rgba(9, 50, 68, 0.702140231092437) 99%);
        background: linear-gradient(180deg, rgba(9, 50, 68, 1) 0%, rgba(9, 50, 68, 0.702140231092437) 99%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#093244", endColorstr="#093244", GradientType=1);

        .container {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            gap: 2rem;


            h1 {
                font-size: 4rem;
                text-align: center;
                text-transform: uppercase;
                color: white;
            }

            p {
                max-width: 50vw;
                margin-inline: auto;
            }

            .button {
                background-color: white;
                color: $primary-color;
            }
        }
    }
}

.steps {
    display: grid;
    height: fit-content;
    gap: 1rem;
}

.step {
    background-color: #DBE0E7;
    max-height: 58px;
    overflow: hidden;

    .content {
        display: flex;
        align-items: center;
        gap: 1rem;

        .number {
            height: 58px;
            width: 58px;
            background-color: $primary-color;
            color: white;
            font-size: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .details {
        padding: 1rem 2rem;

        p {
            font-size: 1.2rem;
        }
    }
}

.open-step {
    animation: linear animateStep 2s forwards;
}

.close-step {
    animation: ease-in-out closeStep 1s forwards;
}

@keyframes animateStep {
    from {
        max-height: 58px;
    }

    to {
        max-height: 500px;
    }
}

@keyframes closeStep {
    from {
        max-height: 500px;
    }

    to {
        max-height: 58px;
    }
}


.contact-form-links,
.social-media-links {
    display: flex;
    align-items: center;
    gap: 2rem;

    .phone,
    .email {
        display: flex;
        align-items: center;
        gap: 0.5rem
    }
}

.popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        background-color: white;
        padding: 2rem;
        display: grid;
        gap: 1rem;
        max-width: 40rem;

        .button {
            background-color: $primary-color;
            color: white;
        }
    }
}

@media screen and (max-width: 768px) {
    .popup {
        .content {
            max-width: 100%;
            height: 100%;
        }
    }

    .thank-you {
        .content {
            height: fit-content;
            justify-items: center;
        }
    }

    .heroSection {
        text-align: center;

        .content {
            .container {
                h1 {
                    font-size: 2rem;
                }

                p {
                    max-width: 100%;
                }
            }
        }
    }

    .contact-form-links {
        flex-direction: column;
        gap: 1rem;
    }
}