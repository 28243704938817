header {
  background-color: #093244;
  color: white;
  height: 100px;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 1000;
  top: 0;
}
header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  margin-inline: auto;
}
header .navigation .logo img {
  max-width: 90px;
}
header .navigation .links {
  display: flex;
  align-items: center;
  gap: 2rem;
}
header .navigation .links .contact-links {
  display: none;
}
header .menu-icon {
  display: none;
}

.nav-action {
  background-color: white;
  color: #093244;
}

@media screen and (max-width: 920px) {
  header .menu-icon {
    display: block;
    position: absolute;
    right: 16px;
    z-index: 1001;
  }
  header .navigation .logo img {
    max-width: 68px;
  }
  header .navigation .links {
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    height: calc(100vh - 100px);
    width: 100vw;
    background-color: #093244;
    z-index: 999;
    font-size: 2rem;
  }
  header .navigation .links .contact-links {
    display: flex;
    align-items: center;
    gap: 24px;
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    justify-content: center;
  }
  header .navigation .open {
    animation: linear animateNavigation 0.3s forwards;
  }
  header .navigation .open .button {
    font-size: 1.5rem;
  }
  @keyframes animateNavigation {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  header .navigation .close {
    animation: closeNavigation 0.3s forwards;
  }
  @keyframes closeNavigation {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}/*# sourceMappingURL=pageHeader.css.map */