* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Inria Sans", sans-serif;
  font-size: 1rem;
}

button,
.button {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;
  border: none;
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #093244;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 3rem;
}

.container {
  width: 90vw;
  margin-inline: auto;
}

.primary {
  background-color: #093244;
  color: white;
  border: 1px solid #093244;
  transition: all 0.5s ease;
}
.primary:hover {
  background-color: transparent;
  color: #093244;
}

.secondary {
  background-color: white;
  color: #093244;
  border: 1px solid #093244;
}
.secondary:hover {
  background-color: #093244;
  color: white;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

input,
textarea {
  padding: 12px 16px;
  font-family: inherit;
  font-size: inherit;
  border: 1px solid gray;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: lightgray;
}
input::placeholder,
textarea::placeholder {
  color: lightgray;
}

.field {
  display: grid;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}

form {
  display: grid;
  gap: 1rem;
}
form .half {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
}
form .half .field {
  flex: 1 1 200px;
}

.form-errors {
  padding: 1rem;
  background-color: rgba(255, 0, 0, 0.244);
}

.form-success {
  padding: 1rem;
  background-color: rgba(0, 128, 0, 0.277);
}

iframe {
  width: 100%;
  height: 350px;
}

section {
  padding: 120px 0;
}
section .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}
section .container .text,
section .container .img,
section .container .form {
  flex: 1 1 300px;
}
section .container .img {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
section .container img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  /* Frame 36 */
  box-shadow: 12px 12px 34px 13px rgba(0, 0, 0, 0.13);
}
section .container .text {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}

.section-highlighted {
  background-color: #F3F5F6;
}

.cta .container {
  background: url("../../../public/images/home/livestyle.webp");
  background-color: #093244;
  background-blend-mode: overlay;
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 3rem;
  text-align: center;
  justify-content: center;
}
.cta .container h1 {
  color: white;
}
.cta .container .button {
  background-color: white;
  color: #093244;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 74px;
  background-color: #093244;
  color: white;
}
footer small {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.loadingSpinner {
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid #F3F5F6;
  border-top: 2px solid rgba(243, 245, 246, 0.3215686275);
  border-radius: 50%;
  animation: linear loadingSpinner 0.7s infinite;
}

.gallery-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 3rem;
}
.gallery-popup .property-image {
  position: relative;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  max-width: 80vw;
  height: auto;
}
.gallery-popup .property-image img {
  width: 100%;
  height: 70vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery-popup .property-image .buttons {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.gallery-popup .property-image .buttons button {
  background-color: rgba(0, 0, 0, 0.303);
  color: white;
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 2rem;
  }
  section {
    padding: 3rem 0;
  }
  section .container {
    text-align: center;
  }
  section .container .text {
    justify-items: center;
  }
  section .container img {
    width: 100%;
    height: 100%;
  }
  .steps,
  form {
    text-align: start;
  }
  .steps button,
  form button {
    width: 100%;
  }
  .gallery-popup .property-image img {
    height: auto;
  }
  .gallery-popup .property-image .buttons {
    bottom: -4rem;
  }
}/*# sourceMappingURL=main.css.map */