.gasogi-properties .heroSection {
  height: 40vh;
}
.gasogi-properties .heroSection .content .container {
  height: 40vh;
}
.gasogi-properties .heroSection .content .container h1 {
  font-size: 2rem;
}

.property-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}
.property-images .image img {
  width: 100%;
  height: 16vw;
}

.gasogi-properties-grid {
  padding: 60px 0;
}
.gasogi-properties-grid .container .property-content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.gasogi-properties-grid:nth-child(even) {
  background-color: #f5f5f5;
}

@media screen and (max-width: 860pc) {
  .property-images {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  .property-images .image img {
    height: 200px;
  }
}
@media screen and (max-width: 720px) {
  .property-images {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
@media screen and (max-width: 480px) {
  .property-images {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}/*# sourceMappingURL=gasogiProperties.css.map */