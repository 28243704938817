.gasogi-properties {
    .heroSection {
        height: 40vh;

        .content {
            .container {
                height: 40vh;

                h1 {
                    font-size: 2rem;
                }
            }
        }
    }
}


.property-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;

    .image {

        img {
            width: 100%;
            height: 16vw;
        }
    }
}

.gasogi-properties-grid {
    padding: 60px 0;

    .container {
        .property-content {
            display: grid;
            height: fit-content;
            gap: 0.5rem;
        }
    }

    &:nth-child(even) {
        background-color: #f5f5f5;
    }
}

@media screen and (max-width:860pc) {
    .property-images {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;

        .image {
            img {
                height: 200px;
            }
        }
    }

}

@media screen and (max-width: 720px) {
    .property-images {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
}

@media screen and (max-width: 480px) {
    .property-images {
        grid-template-columns: 1fr;
        gap: 8px;
    }

}